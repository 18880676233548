import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useDebouncedCallback } from 'use-debounce';
import { searchApi } from '../../fetchers/search';
import { getLangCountry } from '../../helpers/languages';
import { useRouter } from 'next/router';
import ProductTile from '../ProductTile';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';
import { IProductList } from '../../models';
import CloseIcon from '../../public/img/close-grey.svg';
import StyledImage from '../StyledImage';
import { fetchShippingCountriesList } from '../../fetchers/shipping';
import { getCurrencyName } from '../../helpers/prices';

const SEARCH_PRODUCTS_LIMIT = Number(process.env.SEARCH_PRODUCTS_LIMIT) || 8;

interface IMenuSearchProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuSearch: React.FC<IMenuSearchProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [hasMadeRequest, setHasMadeRequest] = useState(false);
  const [products, setProducts] = useState<IProductList[]>([]);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const { lang, country } = getLangCountry(router.query);
  const countries = fetchShippingCountriesList({ lang });
  const currencyCode = getCurrencyName(countries, country);

  const handleSearch = async (concat: boolean) => {
    setLoading(true);

    if (!concat) {
      setProducts([]);
    }

    const response = await searchApi({
      search,
      lang,
      country,
      offset
    });
    setHasMadeRequest(true);
    setLoading(false);

    if (concat) {
      setProducts([...products, ...response.products]);
      trackPageViewEvent([...products, ...response.products]);
    } else {
      setProducts(response.products);
      trackPageViewEvent(response.products);
    }

    setCount(response.count);
  };

  const debouncedSearch = useDebouncedCallback(handleSearch, 800);

  useEffect(() => {
    setOffset(0);

    if (search.length > 2) {
      debouncedSearch(false);
    }
  }, [search]);

  useEffect(() => {
    if (offset !== 0) {
      handleSearch(true);
    }
  }, [offset]);

  const loadMore = () => {
    setOffset(offset => offset + SEARCH_PRODUCTS_LIMIT);
  };

  const resetSearch = () => {
    setSearch('');
    setProducts([]);
    setCount(0);

    setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  const close = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  const trackPageViewEvent = (products: IProductList[]) => {
    const impressions = products.map((product: any, position) => ({
      id: product.selling_code,
      name: product.name,
      price: product.price?.price,
      brand: 'Prima Brands',
      category: '',
      position,
      list: 'Search page'
    }));

    window.dataLayer?.push({
      ecommerce: {
        currencyCode,
        impressions
      }
    });
  };

  const trackClickEvent = (p: IProductList, position: number) => {
    window.dataLayer?.push({
      event: 'productClick',
      ecommerce: {
        currencyCode,
        click: {
          actionField: { list: 'search' },
          products: [
            {
              id: p.selling_code,
              name: p.name,
              price: p.price?.price,
              brand: 'Prima Brands',
              category: '',
              position
            }
          ]
        }
      }
    });

    close();
  };

  return (
    <>
      <div className="ml-3 relative flex">
        <button className="p-2 text-black hover:text-gray-500">
          <span className="sr-only">{t('SEARCH')}</span>
          <MagnifyingGlassIcon
            className="w-6 h-6"
            aria-hidden="true"
            onClick={() => setIsOpen(true)}
          />
        </button>

        <Transition
          as={Fragment}
          show={isOpen}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-50"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog
            as="div"
            className="text-sm text-gray-500 z-10"
            style={{
              position: 'absolute',
              top: 65,
              right: 0,
              bottom: 0,
              left: 0
            }}
            onClose={resetSearch}
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 top-40" />

            <div className="relative bg-white max-h-full h-screen md:h-auto overflow-auto p-8">
              <div className="flex justify-end pb-8 -mr-2">
                <button className="m-0" onClick={resetSearch}>
                  <StyledImage
                    src={CloseIcon}
                    className="w-8 h-8"
                    alt="close icon"
                  />
                </button>
              </div>

              <div className="mx-auto pb-4">
                <input
                  type="text"
                  placeholder={t('MENU_SEARCH_PRODUCT')}
                  className="w-full border-t-0 border-l-0 border-r-0 border-b-1 border-gray-200 text-3xl md:text-6xl placeholder-grey outline-none focus:ring-0 focus:border-gray-400 "
                  value={search}
                  onChange={({ target }) => setSearch(target.value)}
                />
              </div>

              {products.length > 0 ? (
                <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8">
                  {products.map((p, i) => (
                    <ProductTile
                      product={p}
                      key={i}
                      onClick={() => trackClickEvent(p, i)}
                    />
                  ))}
                </div>
              ) : !hasMadeRequest || loading ? null : (
                <div className="w-full text-center py-8">
                  {t('NO_SEARCH_RESULTS')}
                </div>
              )}

              {loading ? (
                <div className="w-full text-center py-8">
                  <Spinner />
                </div>
              ) : (
                products.length < count && (
                  <div className="w-full text-center py-8">
                    <button
                      className="text-center bg-white text-primary px-3 py-2 rounded-lg"
                      onClick={loadMore}
                    >
                      {t('LOAD_MORE')}
                    </button>
                  </div>
                )
              )}
            </div>
          </Dialog>
        </Transition>
      </div>
    </>
  );
};

export default MenuSearch;
