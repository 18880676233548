/* eslint-disable unicorn/no-null */
import { useState } from 'react';
import Link from 'next/link';
import localizedRoute from '../../utils/localizedRoute';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import LinkTo from '../LinkTo';
import { IItemMenu, MenuItem } from '../../models/menu';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../context/reducer';
import { isLogged } from '../../helpers/auth';
import { useTranslation } from 'react-i18next';
import MenuBadge from './MenuBadge';
import { isServer } from '../../helpers/platform';
import { fetchMenu } from '../../fetchers/menu';
import { useRouter } from 'next/router';
import { getLangCountry } from '../../helpers/languages';
import MenuBanners from './MenuBanners';
import parse from 'html-react-parser';

export default function MenuMobile({ close }: { close: () => void }) {
  const { profile = { first_name: '', last_name: '' } } = useSelector<
    IGlobalState,
    IGlobalState
  >(state => state || {});
  const [selectedCategory, setSelectedCategory] = useState<MenuItem | null>(
    null
  );

  const [selectedSubCategory, setSelectedSubCategory] =
    useState<IItemMenu | null>(null);
  const router = useRouter();
  const { lang } = getLangCountry(router.query);
  const menu = fetchMenu({ lang, order: 'mobile' });
  const logged = isLogged();
  const { t } = useTranslation();

  return (
    <>
      {!selectedCategory && (
        <>
          <div className="flex items-center justify-between mb-5">
            <LinkTo
              href={localizedRoute(logged ? '/profile/home' : 'login')}
              className="flex items-center text-base font-normal cursor-pointer"
            >
              <UserCircleIcon className="inline-block h-8 w-8 rounded-full text-primary" />
              {logged ? (
                <span className="ml-3">
                  {profile.first_name} {profile.last_name}
                </span>
              ) : (
                <span className="ml-3">{t('ENTER')}</span>
              )}
            </LinkTo>
            <LinkTo href={localizedRoute(logged ? '/profile/home' : 'login')}>
              <ChevronRightIcon className="ml-3 w-5 h-5" />
            </LinkTo>
          </div>

          <div className="space-y-1">
            {menu?.map(menuItem => {
              const { title, link, columns = [], refresh = false } = menuItem;

              const localizedLink = link ? localizedRoute(link) : '';
              return (
                <div key={title}>
                  <div className="flex items-center justify-between mb-2 cursor-pointer">
                    {!localizedLink ? (
                      <button
                        onClick={() => {
                          if (columns.length > 0) {
                            setSelectedCategory(menuItem);
                          }
                        }}
                        className="w-full flex items-center justify-between font-normal text-2xl py-2"
                      >
                        <span>{parse(title)}</span>

                        <ChevronRightIcon className="ml-3 w-5 h-5" />
                      </button>
                    ) : (
                      <>
                        {refresh ? (
                          <>
                            <button
                              className="block font-normal text-2xl py-2"
                              onClick={() => {
                                window.location.href = localizedLink;
                              }}
                            >
                              {parse(title)}
                            </button>
                            {columns.length > 0 && (
                              <ChevronRightIcon
                                className="ml-3 w-5 h-5"
                                onClick={() => {
                                  if (columns.length > 0) {
                                    setSelectedCategory(menuItem);
                                  }
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            <Link href={localizedLink} prefetch={false}>
                              <a
                                className="block font-normal text-2xl py-2"
                                onClick={() => close()}
                              >
                                {parse(title)}
                              </a>
                            </Link>
                            {columns.length > 0 && (
                              <ChevronRightIcon
                                className="ml-3 w-5 h-5"
                                onClick={() => {
                                  if (columns.length > 0) {
                                    setSelectedCategory(menuItem);
                                  }
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <hr className="my-10" />
          <ul className="mt-10">
            <li
              className="mb-1 cursor-pointer"
              onClick={() => {
                if (isServer()) return;
                close();
                setTimeout(() => {
                  document.getElementById('menu-cart')?.click();
                }, 800);
              }}
            >
              {t('CART')}
            </li>
            <li className="mb-1">
              <Link
                href={localizedRoute(logged ? '/profile/orders' : '/login')}
              >
                {t('ORDERS')}
              </Link>
            </li>
          </ul>
        </>
      )}

      {selectedCategory && !selectedSubCategory ? (
        <div>
          <div
            className="flex flex-col megamenu-column cursor-pointer"
            onClick={() => {
              // eslint-disable-next-line unicorn/no-useless-undefined
              setSelectedCategory(null);
            }}
          >
            <div className="flex items-center mb-1 font-normal">
              <div className="mr-3">
                <ChevronLeftIcon className="w-5 h-5" />
              </div>
              {`${selectedCategory.title}`}
            </div>
          </div>

          {selectedCategory?.columns?.map(column => {
            return column.items.map(item => {
              return item.refresh ? (
                <a
                  className="mt-4 font-light flex items-center"
                  href={localizedRoute(item.link)}
                  onClick={() => close()}
                >
                  {item.title}
                </a>
              ) : (
                <div key={item.title}>
                  <div
                    key={item.title}
                    className="flex flex-col megamenu-column"
                  >
                    <button
                      onClick={() => {
                        setSelectedSubCategory(item);
                      }}
                      className="mt-4 font-light flex items-center"
                    >
                      {parse(item.title)}
                      {item.labels &&
                        item.labels.map(text => (
                          <MenuBadge text={text} key={text} />
                        ))}
                    </button>
                  </div>
                </div>
              );
            });
          })}
          {selectedCategory.banners && (
            <div className="mt-4">
              <MenuBanners
                banners={selectedCategory.banners}
                closeOnClick={() => close()}
              />
            </div>
          )}
        </div>
      ) : selectedSubCategory ? (
        <div>
          <div
            className="flex flex-col megamenu-column cursor-pointer"
            onClick={() => {
              // eslint-disable-next-line unicorn/no-useless-undefined
              setSelectedSubCategory(null);
            }}
          >
            <div className="flex items-center mb-1 font-normal">
              <div className="mr-3">
                <ChevronLeftIcon className="w-5 h-5" />
              </div>
              {`${parse(selectedSubCategory.title)}`}
            </div>
          </div>

          <a
            href={localizedRoute(selectedSubCategory.link)}
            className="mt-4 font-light flex items-center"
          >
            {t('ALL_PRODUCTS')}
          </a>

          {selectedSubCategory?.items?.map(item => {
            const localizedItem = localizedRoute(item.link);

            return (
              <div key={item.title}>
                <div key={item.title} className="flex flex-col megamenu-column">
                  {item.refresh ? (
                    <a
                      href={localizedItem}
                      className="mt-4 font-light flex items-center"
                    >
                      {parse(item.title)}
                      {item.labels &&
                        item.labels.map(text => (
                          <MenuBadge text={text} key={text} />
                        ))}
                    </a>
                  ) : (
                    <Link href={localizedItem}>
                      <a
                        className="mt-4 font-light flex items-center"
                        onClick={() => close()}
                      >
                        {parse(item.title)}
                        {item.labels &&
                          item.labels.map(text => (
                            <MenuBadge text={text} key={text} />
                          ))}
                      </a>
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
