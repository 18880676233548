/* eslint-disable unicorn/no-null */
import React, { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import Link from 'next/link';
import localizedRoute from '../../utils/localizedRoute';
import { MenuItem } from '../../models/menu';
import { classNames } from '../../helpers/classes';
import MenuBadge from './MenuBadge';
import { fetchMenu } from '../../fetchers/menu';
import { useRouter } from 'next/router';
import { getLangCountry } from '../../helpers/languages';
import MenuBanners from './MenuBanners';
import parse from 'html-react-parser';

export default function Megamenu() {
  const [bannerShown, setBannerShown] = useState(false);
  const router = useRouter();
  const { lang } = getLangCountry(router.query);
  const menu = fetchMenu({ lang, order: 'desktop' });
  const [mainCategory, setMainCategory] = useState<MenuItem | null>(null);
  const popoverButtons = useRef<HTMLElement[]>([]);

  useEffect(() => {
    setBannerShown(!sessionStorage.getItem('show_shipping_banner'));
  });

  return (
    <div className="hidden h-full lg:flex">
      <Popover.Group className="px-4 bottom-0 inset-x-0">
        <div className="h-full flex justify-center space-x-8 z-10">
          {menu.map((menuItem, i) => (
            <Popover key={i} className="flex">
              {({ open, close }) => {
                return (
                  <>
                    <div className="relative flex">
                      <button
                        onMouseEnter={() => {
                          setMainCategory(menuItem);
                          if (!open) {
                            popoverButtons.current[i].click();
                          }
                        }}
                        className={classNames(
                          open
                            ? 'border-primary text-primary'
                            : 'border-transparent text-gray-700 hover:text-gray-800',
                          'relative z-10 flex items-center justify-center transition-colors ease-out duration-200 font-medium border-b-2 -mb-px pt-px',
                          menuItem.link ? 'cursor-pointer' : 'cursor-auto'
                        )}
                      >
                        {menuItem.link ? (
                          <a href={localizedRoute(menuItem.link)}>
                            {parse(menuItem.title)}
                          </a>
                        ) : (
                          <div>{parse(menuItem.title)}</div>
                        )}
                      </button>
                      <Popover.Button as="div" className="invisible">
                        <span
                          ref={el => {
                            if (el) {
                              popoverButtons.current[i] = el;
                            }
                          }}
                        />
                      </Popover.Button>
                    </div>

                    {menuItem.columns && menuItem.columns.length > 0 && (
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Popover.Panel
                          className="absolute top-full inset-x-0 text-sm text-gray-500 z-40"
                          onMouseLeave={() => {
                            close();
                          }}
                        >
                          <div
                            className="absolute inset-0 top-1/2 bg-white shadow"
                            aria-hidden="true"
                          />

                          <div className="relative bg-white">
                            <div className="max-w-1680 mx-auto px-8">
                              <div className="grid gap-y-10 gap-x-8 py-16">
                                {/* <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                  {menuItem.banners && (
                                    <MenuBanners banners={menuItem.banners} />
                                  )}
                                </div> */}

                                <div className="row-start-1 grid grid-cols-4 gap-y-10 gap-x-8">
                                  {mainCategory?.columns?.map((column, i) => {
                                    return (
                                      <div key={i}>
                                        {column.items.map(item => {
                                          const localizedLink = localizedRoute(
                                            item.link
                                          );

                                          return (
                                            <div
                                              key={item.title}
                                              className="mb-10"
                                            >
                                              {item.refresh ? (
                                                <a
                                                  id={`${item.title}-heading`}
                                                  className="font-medium text-gray-900 flex items-center"
                                                  href={localizedLink}
                                                >
                                                  {parse(item.title)}
                                                  {item.labels &&
                                                    item.labels.map(
                                                      (text, i) => (
                                                        <MenuBadge
                                                          text={text}
                                                          key={i}
                                                        />
                                                      )
                                                    )}
                                                </a>
                                              ) : (
                                                <Link
                                                  href={localizedLink}
                                                  prefetch={false}
                                                >
                                                  <a
                                                    id={`${item.title}-heading`}
                                                    className="font-medium text-gray-900 flex items-center"
                                                    onClick={() => close()}
                                                  >
                                                    {parse(item.title)}
                                                    {item.labels &&
                                                      item.labels.map(
                                                        (text, i) => (
                                                          <MenuBadge
                                                            text={text}
                                                            key={i}
                                                          />
                                                        )
                                                      )}
                                                  </a>
                                                </Link>
                                              )}

                                              <ul
                                                aria-labelledby={`${item.title}-heading`}
                                                className="mt-6 space-y-6 sm:mt-3 sm:space-y-3"
                                              >
                                                {item.items?.map((item, i) => (
                                                  <li key={i} className="flex">
                                                    <Link
                                                      href={localizedRoute(
                                                        item.link
                                                      )}
                                                      prefetch={false}
                                                    >
                                                      <a
                                                        className="hover:text-gray-800 text-sm flex items-center"
                                                        onClick={() => close()}
                                                      >
                                                        <span
                                                          className={`${
                                                            item.sport
                                                              ? 'font-semibold'
                                                              : ''
                                                          }`}
                                                        >
                                                          {parse(item.title)}
                                                        </span>
                                                        <span>
                                                          {item.labels?.map(
                                                            (text, i) => (
                                                              <MenuBadge
                                                                text={text}
                                                                key={i}
                                                              />
                                                            )
                                                          )}
                                                        </span>
                                                      </a>
                                                    </Link>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    );
                                  })}
                                  {menuItem.banners && (
                                    <MenuBanners
                                      closeOnClick={() => close()}
                                      banners={menuItem.banners}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    )}

                    {menuItem.columns && menuItem.columns.length > 0 && (
                      <Popover.Overlay
                        className="fixed inset-0 bg-black opacity-50 z-20"
                        style={{
                          top: bannerShown ? 113 : 65
                        }}
                      />
                    )}
                  </>
                );
              }}
            </Popover>
          ))}
        </div>
      </Popover.Group>
    </div>
  );
}
