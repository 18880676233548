/* eslint-disable no-empty */
/* eslint-disable unicorn/prefer-optional-catch-binding */
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { ShoppingBagIcon, TruckIcon } from '@heroicons/react/24/solid';
import usePrice from '../../hooks/usePrice';
import GrayButton from '../GrayButton';
import SecondaryButtonBig from '../SecondaryButtonBig';
import { ICartProduct, ICart } from '../../models/cart';
import { removeProduct, updateProduct } from '../../fetchers/cart';
import { getLangCountry } from '../../helpers/languages';
import useQueryParams from '../../hooks/useQueryParams';
import { SET_SESSION_CART } from '../../context/types';
import StyledImage from '../StyledImage';
import visa from '../../public/img/visa-grey.svg';
import mastercard from '../../public/img/mastercard-gray.svg';
import paypal from '../../public/img/paypal-grey.svg';
import postepay from '../../public/img/postepay-gray.svg';
import amex from '../../public/img/amex-gray.svg';
import CartProduct from './CartProduct';
import { useAlert } from '../../context/AlertProvider';
import { useTranslation } from 'react-i18next';
import MiniSpinner from '../MiniSpinner';
import { useSelector, useStore } from 'react-redux';
import { IGlobalState } from '../../context/reducer';
import LinkTo from '../LinkTo';
import { getToken } from '../../helpers/auth';
import * as pixelFB from '../../helpers/pixelEvents';
// import WidgetScalapay from '../WidgetScalapay';

export default function MenuCart({ isSearchOpen }: { isSearchOpen: boolean }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formatPrice] = usePrice();
  const [query] = useQueryParams();
  const { t } = useTranslation();
  const { lang, country } = getLangCountry(query);
  const alert = useAlert();
  const store = useStore();
  const { session = {} } = useSelector<IGlobalState, IGlobalState>(
    state => state || {}
  );
  const {
    products = [],
    total,
    subtotal,
    remaining = 0,
    shipping = 0
  } = (session as ICart).cart || {};

  const token = getToken();
  const CHECKOUT_URL = `${process.env.NEXT_PUBLIC_CHECKOUT_URL}?session_token=${token}`;

  const sendCheckoutEv = () => {
    //PIXEL EVENT Checkout
    // pixelFB.event({ eventName: 'InitiateCheckout' });
  };

  const onChangeQty = async (product: ICartProduct, qty: number) => {
    try {
      setLoading(true);
      const cart = await updateProduct({
        ...getLangCountry(query),
        token,
        sku: product['product-sku'],
        quantity: qty
      });
      store.dispatch({ type: SET_SESSION_CART, payload: cart });
    } catch (error) {
      alert.error(t('GENERIC_ERROR'));
    } finally {
      setLoading(false);
    }
  };

  const onRemoveProduct = async (product: ICartProduct) => {
    try {
      setLoading(true);
      const cart = await removeProduct({
        ...getLangCountry(query),
        token,
        sku: product['product-sku']
      });

      store.dispatch({ type: SET_SESSION_CART, payload: cart });
    } catch (error) {
      alert.error(t('GENERIC_ERROR'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative">
        <button
          id="menu-cart"
          className="bg-white rounded-full flex text-sm focus:outline-none"
          onClick={() => setOpen(true)}
          disabled={isSearchOpen}
        >
          <div className="p-2 text-black hover:text-gray-500 flex relative">
            <span className="sr-only">Cart</span>
            <ShoppingBagIcon className="w-6 h-6" aria-hidden="true" />

            <span className="absolute top-5 right-2 h-4 w-4 rounded-full border border-black bg-white flex items-center justify-center text-black font-bold text-9px">
              {products.length}
            </span>
          </div>
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          auto-reopen="true"
          className="fixed inset-0 ovw-screen max-w-mderflow-hidden z-20"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md md:max-w-4xl">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                    <div className="flex-1 py-12 overflow-y-auto px-4 sm:px-12">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-5xl font-medium text-gray-900 font-sans">
                          {t('CART')}
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            className="-m-2 py-3 px-4 rounded-md text-sm hover:text-gray-500 focus:outline-none bg-gray-100"
                            onClick={() => setOpen(false)}
                          >
                            {t('CLOSE')}
                          </button>
                        </div>
                      </div>

                      <hr className="mb-6 mt-2" />

                      <div>
                        <div className="flow-root">
                          {products.length > 0 ? (
                            <ul className="-my-6">
                              {products.map((product: ICartProduct) => (
                                <li
                                  key={product['product-sku']}
                                  className="py-6 flex"
                                >
                                  <CartProduct
                                    product={product}
                                    onChangeQty={onChangeQty}
                                    onRemoveProduct={onRemoveProduct}
                                  />
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <div className="flex justify-center items-center h-full">
                              <p>{t('EMPTY_CART')}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 py-10 px-4 sm:px-12">
                      <div className="mb-3 flex flex-col">
                        <div className="flex justify-between text-sm font-medium text-gray-400 mb-4">
                          <p>{t('SUBTOTAL')}</p>
                          <p>{formatPrice(subtotal)}</p>
                        </div>
                        <div className="flex justify-between text-sm font-medium text-gray-400 mb-4">
                          <p>{t('SHIPPING_FEES')}</p>
                          <p>{formatPrice(shipping)}</p>
                        </div>

                        {Number(remaining) > 0 ? (
                          <div className="bg-green-100 p-3 inline-flex ml-auto rounded-md items-center">
                            <div className="bg-green-600 w-6 h-6 rounded-sm flex items-center justify-center mr-2">
                              <TruckIcon className="h-4 w-4 text-green-100" />
                            </div>
                            <p className="text-sm text-green-600 text-right">
                              {t('CART_SHIPPING_FREE_MESSAGE_1')}{' '}
                              <b className="font-semibold text-green-600">
                                {formatPrice(Math.max(0, remaining))}
                              </b>{' '}
                              {t('CART_SHIPPING_FREE_MESSAGE_2')}
                            </p>
                          </div>
                        ) : null}
                      </div>

                      <div className="flex flex-col mb-4">
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <p>{t('TOTAL')}</p>
                          <p className="inline-flex items-center">
                            {loading && <MiniSpinner />}{' '}
                            {products.length > 0
                              ? formatPrice(total)
                              : formatPrice(0)}
                          </p>
                        </div>
                        {/* <div className="text-right">
                          <WidgetScalapay lang={lang} amount={total} />
                        </div> */}
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <LinkTo href={CHECKOUT_URL}>
                          <SecondaryButtonBig
                            text={t('GO_TO_CHECKOUT')}
                            onClick={sendCheckoutEv}
                          />
                        </LinkTo>
                        <GrayButton
                          text={t('CONTINUE_SHOPPING')}
                          onClick={() => setOpen(false)}
                        />
                      </div>
                      <p className="mt-6 text-sm text-center text-gray-500">
                        {t('PAY_SECURE')}
                      </p>
                      <div className="flex justify-between mt-3 w-2/3 mx-auto">
                        <StyledImage
                          src={visa}
                          className="w-12"
                          alt="visa logo"
                        />
                        <StyledImage
                          src={mastercard}
                          className="w-12"
                          alt="mastercard logo"
                        />
                        <StyledImage
                          src={paypal}
                          className="w-12"
                          alt="paypal logo"
                        />
                        <StyledImage
                          src={postepay}
                          className="w-12"
                          alt="postepay logo"
                        />
                        <StyledImage
                          src={amex}
                          className="w-12"
                          alt="american express logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
