import Image from 'next/image';
import Link from 'next/link';
import localizedRoute from '../../utils/localizedRoute';
import { IBannerMenu } from '../../models/menu';
import LinkTo from '../LinkTo';

function MenuBanners({
  closeOnClick,
  banners
}: {
  closeOnClick: any;
  banners: IBannerMenu[];
}) {
  return (
    <>
      {banners.map((banner, i) => (
        <div key={i} className="group relative text-base sm:text-sm">
          {/* group-hover:opacity-75 */}
          <div
            className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden flex items-center"
            onClick={() => closeOnClick()}
          >
            <Link href={localizedRoute(banner.link)} prefetch={false}>
              <Image
                alt="menu img"
                src={banner.image || ''}
                layout="fill"
                className="object-center object-cover "
              />
            </Link>
            {/* <div className="bg-gray-600 opacity-20"></div> */}
            <LinkTo
              href={localizedRoute(banner.link)}
              className="flex items-end pl-4 pb-4"
            >
              <div className="py-2 px-2 rounded-md bg-primary text-white">
                <span className="absolute z-10" aria-hidden="true"></span>
                {banner.title}
              </div>
            </LinkTo>
          </div>
        </div>
      ))}
    </>
  );
}

export default MenuBanners;
