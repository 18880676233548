/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import SelectCountry, { Flag } from './SelectCountry';
import {
  DEFAULT_LANG,
  getLangCountry,
  STRING_LANG,
  SUPPORTED_LANG
} from '../helpers/languages';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { useStore } from 'react-redux';
import { SET_SESSION_DATA } from '../context/types';
import Spinner from './Spinner';
import BlueButtonBig from './BlueButtonBig';
import { ICountryListItem } from '../models/footer';
import { getCountryName, getFlagCode } from '../helpers/countries';
import { fetchShippingCountriesList } from '../fetchers/shipping';
import { fetchAdvisedUrl } from '../fetchers/geolocation';
import { fetchSession } from '../fetchers/sessions';

export default function ChangeLangCountry() {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();
  const store = useStore();
  const { lang, country } = getLangCountry(router.query);
  const [selectedLang, setSelectedLang] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [cookies, setCookie] = useCookies([
    'prima_bearer',
    'prima_actual_country'
  ]);
  const shippingCountriesList = fetchShippingCountriesList({ lang });
  const [previousShippingCountry, setPreviousShippingCountry] =
    useState<ICountryListItem | null>(null);
  const [currentShippingCountry, setCurrentShippingCountry] =
    useState<ICountryListItem | null>(null);

  // set valori iniziali (router.query.lang al primo giro è vuoto)
  useEffect(() => {
    if (router.query.lang) {
      if (lang && !selectedLang) {
        setSelectedLang(lang);
      }
      if (country && !selectedCountry) {
        setSelectedCountry(country);
      }
    }
  }, [router.query.lang]);

  useEffect(() => {
    if (router.query?.lang && selectedCountry) {
      const currentShippingCountry = getCurrentShippingCountry();

      setCurrentShippingCountry(currentShippingCountry);

      if (!previousShippingCountry) {
        setPreviousShippingCountry(currentShippingCountry);
      }
    }
  }, [selectedCountry, shippingCountriesList, router.query.lang, cookies]);

  function getCurrentShippingCountry() {
    const actualCountry = cookies['prima_actual_country'];

    let countryTemp: string;

    if (!actualCountry) {
      countryTemp = selectedCountry;
    } else if (
      (selectedCountry === 'eu' || selectedCountry === 'int') &&
      actualCountry
    ) {
      countryTemp = actualCountry;
    } else {
      countryTemp = selectedCountry;
    }

    return shippingCountriesList.find(c => {
      return c['country-code']?.toLowerCase() === countryTemp.toLowerCase();
    })!;
  }

  useEffect(() => {
    const currentShippingCountry = getCurrentShippingCountry();

    if (currentShippingCountry) {
      setCookie(
        'prima_actual_country',
        currentShippingCountry['country-code']?.toLowerCase(),
        { path: '/' }
      );
    }
  }, [getCurrentShippingCountry()]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const apply = async () => {
    window.scroll(0, 0);

    const storeview = currentShippingCountry?.storeview;

    const apiParams = {
      lang: selectedLang as STRING_LANG,
      country: storeview,
      token: cookies['prima_bearer']
    };

    let translatedUrl: string = `/${
      SUPPORTED_LANG.includes(selectedLang) ? selectedLang : DEFAULT_LANG
    }_${currentShippingCountry?.storeview}`;

    setLoading(true);

    const data = await fetchAdvisedUrl({
      url: router.query.slug || '',
      lang: lang,
      country: selectedCountry
    });

    try {
      if (
        (data.type === 'product' ||
          data.type === 'category' ||
          data.type === 'page') &&
        !Array.isArray(data.translations)
      ) {
        const country = shippingCountriesList.find(
          c =>
            c['country-code']?.toLowerCase() ===
            (selectedLang === 'en' ? 'gb' : selectedLang)
        );

        translatedUrl +=
          `${data.type === 'category' ? '/shop' : '/'}` +
          data.translations[
            country?.storeview === 'gb'
              ? 'en'
              : country?.storeview || DEFAULT_LANG
          ];
      }

      const session = await fetchSession(apiParams);
      store.dispatch({
        type: SET_SESSION_DATA,
        payload: session.data.session
      });
    } catch (error) {
      console.log('error in change country', error);
    } finally {
      setLoading(false);
      setCookie('prima_country', storeview, { path: '/' });
      setPreviousShippingCountry(currentShippingCountry);
      setCookie('prima_actual_country', selectedCountry, { path: '/' });

      if (data.type === 'page' || router.asPath === `/${lang}_${country}`) {
        window.location.replace(translatedUrl);
      } else {
        router.replace(translatedUrl);
      }
    }

    closeModal();
  };

  const onSelectCountryChange = (value: string) => {
    setSelectedCountry(value.toLowerCase());
  };

  return (
    <>
      <div className="max-w-lg">
        <button
          type="button"
          onClick={openModal}
          className="flex items-center w-full px-2 py-2 border border-gray-300 border-opacity-60 text-white rounded-md shadow-sm focus:outline-none focus:ring-white focus:border-white sm:text-sm"
        >
          <Flag country={getFlagCode(previousShippingCountry)} />
          <span className="text-left pl-2 flex-grow">
            {getCountryName(previousShippingCountry)}
          </span>
          <ChevronDownIcon className="w-4" />
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-white opacity-30" />

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full max-w-4xl py-12 overflow-hidden text-left align-middle transition-all transform bg-black shadow-xl rounded-2xl">
                <div className="px-6 lg:px-48">
                  <button
                    className="bg-white text-primary rounded-full w-8 h-8 flex justify-center items-center m-auto mb-2 focus:outline-none"
                    onClick={closeModal}
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </button>
                  <h3 className="text-white text-3xl mt-4 mb-12 font-sans text-center">
                    {t('CHANGE_LANG_COUNTRY')}
                  </h3>
                  <p className="text-white opacity-60 mb-2">
                    {t('COUNTRY_SHIPPING')}
                  </p>
                  <SelectCountry
                    country={selectedCountry}
                    currentShippingCountry={currentShippingCountry}
                    onChange={onSelectCountryChange}
                  />

                  <p className="text-white opacity-60 mt-6 mb-2">
                    {t('LANGUAGE')}
                  </p>
                  <select
                    id="country"
                    className="block w-full py-2 border border-white border-opacity-60 text-white bg-black rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm mb-8"
                    value={selectedLang}
                    onChange={e =>
                      setSelectedLang(e.target.value as STRING_LANG)
                    }
                  >
                    {SUPPORTED_LANG.map(l => (
                      <option value={l} key={l}>
                        {t(`LANGUAGES.${l}`)}
                      </option>
                    ))}
                  </select>
                  {loading ? (
                    <div className="py-4">
                      <Spinner />
                    </div>
                  ) : (
                    <BlueButtonBig text={t('APPLY')} onClick={apply} />
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
